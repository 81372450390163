import {useState} from "react";
import {AlertColor} from "@mui/material/Alert/Alert";
import {EventWithDetail} from "../../../types/global";
import EventBusService from "../../../js/services/EventBusService";
import {ErrorMessage} from "../enum/ErrorMessage";
import {GlobalMessage} from "../enum/GlobalMessage";
import {useTranslation} from "react-i18next";

export function useEventService(logout: () => void) {
    const [message, setMessage] = useState<string|null>(null);
    const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);
    const [severity, setSeverity] = useState<AlertColor>("error");
    const { t } = useTranslation();

    const showUnauthorizedError = () => {
        setSeverity("error")
        setIsOpenSnackBar(true);
        setMessage(t("authorisationError"));
        logout();
    }

    const showGlobalError = (data: unknown) => {
        const event = data as EventWithDetail;
        setSeverity("error")
        setIsOpenSnackBar(true);
        setMessage((event?.detail as string) ?? '');
    }

    const showGlobalNotification = (data: unknown) => {
        const event = data as EventWithDetail;
        setSeverity("success")
        setIsOpenSnackBar(true);
        setMessage(event?.detail as string ?? '');
    }

    const showDescriptionNotification = (data: unknown) => {
        const event = data as EventWithDetail;
        setSeverity("info");
        setIsOpenSnackBar(true);
        setMessage((event?.detail as string) ?? '');
    }

    const initEvents = () => {
        EventBusService.addEventListener(
            ErrorMessage.NOT_AUTHORIZED,
            showUnauthorizedError
        );

        EventBusService.addEventListener(
            ErrorMessage.GLOBAL_ERROR,
            showGlobalError
        );

        EventBusService.addEventListener(
            GlobalMessage.GLOBAL_NOTIFICATION,
            showGlobalNotification
        )

        EventBusService.addEventListener(
            GlobalMessage.GLOBAL_DESCRIPTION_NOTIFICATION,
            showDescriptionNotification
        )
    };

    const destroyEvents = () => {
        EventBusService.removeEventListener(
            ErrorMessage.NOT_AUTHORIZED,
            showUnauthorizedError
        );

        EventBusService.removeEventListener(
            ErrorMessage.GLOBAL_ERROR,
            showGlobalError
        );
        EventBusService.removeEventListener(
            GlobalMessage.GLOBAL_NOTIFICATION,
            showGlobalNotification
        )

        EventBusService.removeEventListener(
            GlobalMessage.GLOBAL_DESCRIPTION_NOTIFICATION,
            showDescriptionNotification
        )
    }

    return {
        initEvents,
        destroyEvents,
        message,
        isOpenSnackBar,
        severity,
        setIsOpenSnackBar,
        setMessage,
        setSeverity,
    }
}
