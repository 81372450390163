import React, {useEffect} from "react";
import {
    useRoutes,
} from "react-router-dom";
import routesConfig from "../../router/routersConfig";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useUser } from "../user/use/useUser";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {OnboardingStep} from "../common/enum/OnboardingStep";
import LeftSidebarMenu from "../sidebar/LeftSidebarMenu";

function Default(): JSX.Element {
    const routeComponents = useRoutes(routesConfig);
    const { getDemoUserId } = useUser();
    const currentUserInfo = useTypedSelector((state) => state.user.user);
    const demoUserId = useTypedSelector((state) => state.user.demoUserId);

    useEffect(() => {
        if (currentUserInfo && currentUserInfo.onboardingStep < OnboardingStep.MAX_STEP) {
            if (!demoUserId) {
                getDemoUserId();
            }
        }
        // eslint-disable-next-line
    }, [currentUserInfo]);

    return (
        <Grid container spacing={2} className={"main"}>
            <Grid item className={"main__sidebar"}>
            <LeftSidebarMenu />
            </Grid>
            <Grid item className={"main__content"}>
                <Box component="div">
                    {routeComponents}
                </Box>
            </Grid>
        </Grid>
    );
}

export default Default;
