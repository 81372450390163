import {Dispatch} from "redux";
import {Auth} from "../../types/auth";
import {UserActionTypes} from "../enum";

export const setStateUser = (user: Auth.User | null):any => {
    return (dispatch: Dispatch<Auth.UserAction>) =>{
        dispatch({type: UserActionTypes.FETCH_USER, payload: {user: user}})
    }
}

export const changePage = (isLoginPage: boolean):any => {
    return (dispatch: Dispatch<Auth.UserAction>) =>{
        dispatch({type: UserActionTypes.CHANGE_LOGIN_PAGE, payload: {isLoginPage: isLoginPage}})
    }
}

export const setDemoUserId = (demoUserId: number | null):any => {
    return (dispatch: Dispatch<Auth.UserAction>) =>{
        dispatch({type: UserActionTypes.SET_DEMO_USER_ID, payload: {demoUserId: demoUserId}})
    }
}

export const setIsMobileDevice = (isMobile: boolean):any => {
    return (dispatch: Dispatch<Auth.UserAction>) =>{
        dispatch({type: UserActionTypes.SET_IS_MOBILE_DEVICE, payload: {isMobile: isMobile}})
    }
}