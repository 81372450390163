import React, {Dispatch, SetStateAction} from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {AlertColor} from "@mui/material/Alert/Alert";

type SnackBarProps = {
    isOpen: boolean;
    severity?: AlertColor;
    message?: string | null,
    handleClose: Dispatch<SetStateAction<boolean>>
}
function SnackBar(props: SnackBarProps)  {
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        props.handleClose(false);
    };

    return (
        <Snackbar
            open={props.isOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'center',}}
            autoHideDuration={3000}
            onClose={handleClose}>
            <Alert onClose={handleClose}
                   className={"alert"}
                   severity={props.severity ?? "success"}
                   sx={{ width: "100%" }}>
                {props.message ?? "Запись успешно отредактирована"}
            </Alert>
        </Snackbar>
    );
}

export default SnackBar;

