import React from "react";
import {NavLink, useLocation} from "react-router-dom";
import "../../../src/assets/scss/sidebar.scss";
import Button from "@mui/material/Button";

type NavLinkProps = {
    path: string | null,
    title: string,
    icon: JSX.Element,
    clickHandler: (() => void) | undefined,
}
function NavLinkMenu(props: NavLinkProps): JSX.Element {
    const location = useLocation().pathname;

    if (!props.path) {
        return (
            <Button
                className="sidebar__nav-link"
                onClick={props.clickHandler}
            >
                {props.icon}
                {props.title}
            </Button>
        )
    }

    const isClientPage = location.includes("client") && props.path.includes("clients");
    const isNutritionPage = location.includes("nutrition") && props.path.includes("nutrition");

    return (
        <NavLink
            className={`sidebar__nav-link ${ (isClientPage || isNutritionPage) ? "active" : ""}`}
            to={props.path}
        >
            {props.icon}
            {props.title}
        </NavLink>
    );
}

export default NavLinkMenu;
