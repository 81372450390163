import { Auth } from "../../types/auth";
import ApiService from "./ApiService";

/**
 * Сервис авторизации
 */
export class AuthService {
    private readonly tokenName = 'auth_token';

    public getToken(): string | null {
        return localStorage.getItem(this.tokenName);
    }

    private setToken(token: string | null): void {
        if (token) {
            localStorage.setItem(this.tokenName, token);
        } else {
            localStorage.removeItem(this.tokenName);
        }
    }

    public async login(params: Auth.LoginRequest): Promise<boolean> {
        try {
            const login = await ApiService.getRequest<Auth.LoginResponse>(
                'post',
                'user/login',
                {
                    params,
                }
            );

            this.setToken(login.token);

            return Promise.resolve(true);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async refreshToken() {
        try {
            const refreshToken: Auth.RefreshTokenResponse = await ApiService.getRequest(
                'post',
                'user/refresh',
            );

            this.setToken(refreshToken.token);
        } catch (e) {
            console.log(e);
        }
    }

    public logout(): void {
        this.setToken(null);
    }
}

const authService = new AuthService();

export default authService;
