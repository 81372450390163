import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useActions} from "../../../hooks/useAction";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {Auth} from "../../../types/auth";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import EventBusService from "../../../js/services/EventBusService";
import {GlobalMessage} from "../../common/enum/GlobalMessage";
import {ReactSVG} from "react-svg";
import UserApi from "../../user/api/UserApi";
import {OnboardingStep} from "../../common/enum/OnboardingStep";
import {EventWithDetail} from "../../../types/global";

export function useOnboarding() {
    const { t } = useTranslation();
    const currentUserInfo = useTypedSelector((state) => state.user.user);
    const demoUserId = useTypedSelector((state) => state.user.demoUserId);
    const [step, setStep] = useState<number | null>(currentUserInfo?.onboardingStep ?? 0);
    const ACTIVE_STEP_CLASS = "onboarding-active-step";
    const SHOW_ONBOARDING = "onboarding-active";
    const {setStateUser} = useActions();
    const navigate = useNavigate();
    const [openPopup, setOpenPopup] = useState(false);
    const [startStep, setStartStep] = useState<number | null>(currentUserInfo?.onboardingStep ?? 0);
    const [element, setElement] = useState<Element | null>(null);
    const [bubbleCoordinate, setBubbleCoordinate] = useState<{x: number, y: number, width: number, height: number} | null>(null);

    const updateStepOnboarding = async (stepNumber: number) => {
        try {
            await UserApi.updateOnboardingStep(stepNumber);
        } catch (e: any){
            console.log(e);
        }
    }

    const findTargetElement = () => {
        let targetElement = document.querySelector(`[data-onboarding-step="${step}"]`);
        if (targetElement) {
            setElement(targetElement);
        }
    }

    /**
     * First render
     */
    useEffect(() => {
        initEvents();
        initPage();

        return () => {
            restoreEvents();
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (currentUserInfo && (startStep !== null) && (startStep < currentUserInfo.onboardingStep)) {
            updateStepOnboardingHandler();
        }
        // eslint-disable-next-line
    }, [currentUserInfo]);

    const clickTarget = (data: unknown) => {
        const stepCount = (data as EventWithDetail).detail as number;

        if (stepCount === 1) {
            setBubbleCoordinate(null);
            const urlResult = `/client/${demoUserId}/info`;
            navigate(urlResult);
        }

        if (stepCount === 5) {
            const urlResult = `/client/${demoUserId}/recommendation`;
            navigate(urlResult);
        }

        if (stepCount === 8) {
            const urlResult = `/client/${demoUserId}/analysis`;
            navigate(urlResult);
        }

        if (stepCount === 10 || stepCount === 15) {
            EventBusService.triggerEvent(
                GlobalMessage.OPEN_POPUP_ONBOARDING,
            );
            changeStepHandler(stepCount);
            return;
        }

        if (stepCount === 12 || stepCount === 16 || stepCount === 17) {
            changeStepHandler(stepCount);
            return;
        }

        if (stepCount === 13) {
            const urlResult = `/client/${demoUserId}/supplement`;
            navigate(urlResult);
        }

        if (stepCount === 18) {
            const urlResult = `/client/${demoUserId}/diets`;
            navigate(urlResult);
        }

        setStep(null);
    }

    useEffect(() => {
        setTimeout(() => {
            findTargetElement();
        }, 300);
        // eslint-disable-next-line
    }, [step]);

    const initPage = async () => {
        if (!currentUserInfo || !demoUserId) return;

        if (currentUserInfo.onboardingStep === 0 || currentUserInfo.onboardingStep === 1) {
            if (window.location.href !== "/") {
                navigate("/");
            }
        }

        if (currentUserInfo.onboardingStep === 2) {
            if (!window.location.href.includes("info")) {
                const urlResult = `/client/${demoUserId}/info`;
                navigate(urlResult);
            }
        }

        if (currentUserInfo.onboardingStep === 6) {
            if (!window.location.href.includes("recommendation")) {
                const urlResult = `/client/${demoUserId}/recommendation`;
                navigate(urlResult);
            }
        }

        if (currentUserInfo.onboardingStep === 9) {
            if (!window.location.href.includes("analysis")) {
                const urlResult = `/client/${demoUserId}/analysis`;
                navigate(urlResult);
            }
        }

        if (currentUserInfo.onboardingStep === 14) {
            if (!window.location.href.includes("supplement")) {
                const urlResult = `/client/${demoUserId}/supplement`;
                navigate(urlResult);
            }
        }

        if (currentUserInfo.onboardingStep === 19) {
            if (!window.location.href.includes("diets")) {
                const urlResult = `/client/${demoUserId}/diets`;
                navigate(urlResult);
            }
        }

        if (currentUserInfo.onboardingStep === 21) {
            if (window.location.href !== "/") {
                navigate("/");
            }
        }
    }

    const updateStepOnboardingHandler = () => {
        if (
            currentUserInfo?.onboardingStep === 2 ||
            currentUserInfo?.onboardingStep === 6 ||
            currentUserInfo?.onboardingStep === 9 ||
            currentUserInfo?.onboardingStep === 14 ||
            currentUserInfo?.onboardingStep === 19 ||
            currentUserInfo?.onboardingStep === 21
        ) {
            updateStepOnboarding(currentUserInfo.onboardingStep);
            setStartStep(currentUserInfo.onboardingStep);
        }
    }

    const skipHandler = () => {
        openPopupHandler();
    }

    const initEvents = () => {
        EventBusService.addEventListener(
            GlobalMessage.LOAD_DATA_PAGE,
            loadData
        );

        EventBusService.addEventListener(
            GlobalMessage.CLICK_ONBOARDING_ELEMENT,
            clickTarget
        );
    }

    const restoreEvents = () => {
        EventBusService.removeEventListener(
            GlobalMessage.LOAD_DATA_PAGE,
            loadData
        );
        EventBusService.removeEventListener(
            GlobalMessage.CLICK_ONBOARDING_ELEMENT,
            clickTarget
        );
    }

    const loadData = (data: unknown) => {
        const stepCount = (data as EventWithDetail).detail as number;
        changeStepHandler(stepCount);
    }

    /**
     * First step
     */
    const beginHandler = () => {
        updateStep(false, 1);
    }

    /**
     * Update step
     * @param isBack
     * @param step
     */
    const updateStep = (isBack = false, step: number) => {

        if (!currentUserInfo) return;

        const newUserInfo = {
            ...currentUserInfo,
            onboardingStep: isBack
                ? (currentUserInfo.onboardingStep ?? 0) - step
                : (currentUserInfo.onboardingStep ?? 0) + step,
        } as  Auth.User;

        setStateUser(newUserInfo);
        setStep(isBack
            ? ((currentUserInfo.onboardingStep ?? 0) - step)
            : ((currentUserInfo.onboardingStep ?? 0) + step));
    }

    const changeStepHandler = (count: number) => {
        if (!currentUserInfo) return;

        const newUserInfo = {
            ...currentUserInfo,
            onboardingStep: count
        } as  Auth.User;

        setStateUser(newUserInfo);
        setStep(count);
    }

    /**
     * get Info by element for step
     * @param element
     */
    const getInfoElement = (element: Element) => {
        const rect = element.getBoundingClientRect();
        const x = rect.left + window.scrollX;
        const y = rect.top + window.scrollY;
        const height = element.clientHeight;
        const width = element.clientWidth;

        if (x) {
            if (!bubbleCoordinate) {
                setBubbleCoordinate({x, y, width, height});
            } else {
                if (width !== bubbleCoordinate.width ||
                    height !== bubbleCoordinate.height ||
                    x !== bubbleCoordinate.x ||
                    y !== bubbleCoordinate.y) {
                    setBubbleCoordinate({x, y, width, height});
                }
            }
        }

        return ((!x || !y || !width || !height) && bubbleCoordinate) ? bubbleCoordinate : { x, y, height, width };
    }

    const nextClickHandler = () => {
        removeClass();
        setStep(null);

        if (step === 20) {
            navigate("/");
            return;
        }

        updateStep(false, 1);
    }

    const removeClass = () => {
        document.querySelectorAll(`.${ACTIVE_STEP_CLASS}`).forEach((item) => {
            item.classList.remove(ACTIVE_STEP_CLASS);
        });
    }

    const finishHandler = () => {
        updateStepOnboarding(OnboardingStep.MAX_STEP);
        document.body.classList.remove(SHOW_ONBOARDING);
        setStep(OnboardingStep.MAX_STEP);
        removeClass();

        const newUserInfo = {
            ...currentUserInfo,
            onboardingStep: OnboardingStep.MAX_STEP
        } as  Auth.User;

        setStateUser(newUserInfo);
    }

    const getBlock = (target: Element | null) => {
        const result = document.querySelector(`[data-onboarding-step="${step}"]`);

        if (step === null) {
            return <></>;
        }

        if (step === 0) {
            return (
                <div className={"onboarding-layout__main-popup"}>
                    <div className={"onboarding-layout__main-popup-content"}>
                        <p className={"onboarding-layout__main-popup-title"}>
                            {t("welcome")},<br/>
                            {currentUserInfo?.name} {currentUserInfo?.surname}!<br/><br/>
                            {t("onboardingSuccessfulRegistration")} 🎉 <br/>
                            {t("onboardingShowAround")}
                        </p>
                        <p className={"onboarding-layout__main-popup-description"}>
                            {t("onboardingClientsPage")}
                        </p>
                        <div className={"onboarding-layout__main-popup-buttons"}>
                            <Button
                                type={"button"}
                                className={"btn btn--gray"}
                                onClick={skipHandler}
                            >
                                {t("skip")}
                            </Button>
                            <Button
                                onClick={beginHandler}
                                type={"button"}
                                className={"btn btn--full"}
                            >
                                {t("begin")}
                            </Button>
                        </div>
                    </div>
                </div>)
        }

        removeClass();

        if (result) {
            result.classList.add(ACTIVE_STEP_CLASS);

            const {x, y, height, width} = getInfoElement(result);

            if (step === 1) {
                const message = t("onboardingClientCard");
                return getBubble(y, x, height, width, message, false, true, "left", false);
            }

            if (step === 2) {
                const message = <p dangerouslySetInnerHTML={{ __html: t('onboardingAddClientInfo') }}></p>
                return getBubble(y, x, height, width, message, true, false, "left", false);
            }

            if (step === 3) {
                const message = t("onboardingClickClientSymptoms");
                return getBubble(y, x, height, width, message, true, true, "left", false);
            }

            if (step === 4) {
                const message = t("onboardingClickClientQuestionnaire");
                return getBubble(y, x, height, width, message, true, true, "left", false);
            }

            if (step === 5) {
                const message = t("onboardingGoToRecommendations");
                return getBubble(y, x, height, width, message, false, true, "bottom", false);
            }

            if (step === 6) {
                const message = t("onboardingRecommendationsPage");
                return getBubble(y, x, height, width, message, true, false, "left", true);
            }

            if (step === 7) {
                const message = t("onboardingAddRecommendations");
                return getBubble(y, x, height, width, message, true, true, "top", false);
            }

            if (step === 8) {
                const message = t("onboardingGoToAnalysis");
                return getBubble(y, x, height, width, message, false, true, "bottom", false);
            }

            if (step === 9) {
                const message = t("onboardingAddAnalysis");
                return getBubble(y, x, height, width, message, false, true, "bottom", false);
            }
            if (step === 10) {
                document.body.classList.add(SHOW_ONBOARDING);
                const message = t("onboardingAnalysisPopup");
                return getBubble(y, x, height, width, message, true, false, "top", false);
            }

            if (step === 11) {
                document.body.classList.remove(SHOW_ONBOARDING);
                const message = <p dangerouslySetInnerHTML={{ __html: t('onboardingAnalysisTable') }}></p>
                return getBubble(y, x, height, width, message, false, true, "left", true);
            }

            if (step === 12) {
                document.body.classList.add(SHOW_ONBOARDING);
                const message = t("onboardingAnalysisHistory");
                return getBubble(y, x, height, width, message, true, false, "top", false);
            }

            if (step === 13) {
                document.body.classList.remove(SHOW_ONBOARDING);
                const message = t("onboardingGoToSupplements");
                return getBubble(y, x, height, width, message, false, true, "bottom", false);
            }

            if (step === 14) {
                document.body.classList.remove(SHOW_ONBOARDING);
                const message = t("onboardingAddSupplement");
                return getBubble(y, x, height, width, message, false, true, "bottom", false);
            }

            if (step === 15) {
                document.body.classList.add(SHOW_ONBOARDING);
                const message = t("onboardingSupplementPopup");
                return getBubble(y, x, height, width, message, true, false, "left", false);
            }

            if (step === 16) {
                document.body.classList.remove(SHOW_ONBOARDING);
                const message = t("onboardingScheduleDayBtn");
                return getBubble(y, x, height, width, message, false, true, "left", false);
            }

            if (step === 17) {
                document.body.classList.add(SHOW_ONBOARDING);
                const message = t("onboardingScheduleDay");
                return getBubble(y, x, height, width, message, true, true, "left", false);
            }

            if (step === 18) {
                document.body.classList.remove(SHOW_ONBOARDING);
                const message = t("onboardingGoToDiet");
                return getBubble(y, x, height, width, message, false, true, "bottom-left", false);
            }

            if (step === 19) {
                const message = t("onboardingDietsPage");
                return getBubble(y, x, height, width, message, true, false, "left", true);
            }

            if (step === 20) {
                const message = t("onboardingGetPdf");
                return getBubble(y, x, height, width, message, true, true, "bottom", false);
            }

            if (step === 21) {
                const message = t("onboardingAddFirstClient");
                return getBubble(y, x, height, width, message, false, true, "bottom", false);
            }

            if (step === 22) {
                document.body.classList.add(SHOW_ONBOARDING);
                const message = t("onboardingAddFirstClientInfo");
                return getBubble(y, x, height, width, message, false, false, "top", false, true);
            }
        } else {
            return <></>;
        }
    }


    const getStep = (target: Element | null) => {
        return getBlock(target);
    }

    const backClickOnHandler = () => {
        removeClass();
        setStep(null);

        if (step === 2) {
            navigate("/");
            return;
        }

        if (step === 6 && demoUserId) {
            const urlResult = `/client/${demoUserId}/info`;
            navigate(urlResult);
            setBubbleCoordinate(null);
            return;
        }

        if (step === 9 && demoUserId) {
            const urlResult = `/client/${demoUserId}/recommendation`;
            navigate(urlResult);
            return;
        }

        if (step === 11 || step === 16) {
            EventBusService.triggerEvent(
                GlobalMessage.OPEN_POPUP_ONBOARDING,
            );
        }

        if (step === 13 || step === 18) {
            updateStep(true, 2);
            return;
        }

        if (step === 14 && demoUserId) {
            const urlResult = `/client/${demoUserId}/analysis`;
            navigate(urlResult);
            return;
        }

        if (step === 17) {
            document.body.classList.remove(SHOW_ONBOARDING);
        }

        if (step === 19 && demoUserId) {
            const urlResult = `/client/${demoUserId}/supplement`;
            navigate(urlResult);
            return;
        }


        if (step === 21 && demoUserId) {
            const urlResult = `/client/${demoUserId}/diets`;
            navigate(urlResult);
            return;
        }

        updateStep(true, 1);
    }

    const getBubble = (
        top: number,
        left: number,
        height: number,
        width: number,
        text: string | JSX.Element,
        hasNextBtn: boolean,
        hasIconClick: boolean,
        position: "bottom" | "top" | "left" | "bottom-left",
        isSmall: boolean,
        isEnd = false
    ) => {
        const getTop = () => {
            if (position === "bottom" || position === "bottom-left") {
                return top + height;
            }

            if (position === "top") {
                return top;
            }

            return  top + height / 2;
        }

        const getLeft = () => {
            if (position === "bottom") {
                return left + width / 2;
            }
            if (position === "bottom-left") {
                return left + width;
            }
            if (position === "top") {
                return left + width / 2;
            }

            return left;
        }

        return (
            <div className={`onboarding-layout__bubble ${position} ${isSmall ? "small" : ""} ${isEnd ? "finish" : ""}`}
                 style={{
                     position: "absolute",
                     top: getTop(),
                     left: getLeft()
                 }}
            >
                <Button
                    type={"button"}
                    className={"onboarding-layout__bubble-close"}
                    onClick={skipHandler}
                >X</Button>
                <div className={"onboarding-layout__bubble-text"}>
                    {text}
                    {hasIconClick ? (
                        <ReactSVG src={"/img/svg/ic_tap.svg"} className={"icon-svg"}/>
                    ) : <></>}
                </div>
                <div className={"onboarding-layout__bubble-buttons"}>
                    <Button
                        type={"button"}
                        className={"btn btn--gray"}
                        onClick={backClickOnHandler}
                    >
                        {t("back")}
                    </Button>
                    {isEnd ? (
                        <Button
                            type={"button"}
                            className={"btn btn--full"}
                            onClick={finishHandler}
                        >
                            {t("getStarted")}
                        </Button>
                    ) : <></>}
                    {hasNextBtn ? (
                        <Button
                            type={"button"}
                            className={"btn btn--full"}
                            onClick={nextClickHandler}
                        >
                            {t("further")}
                        </Button>
                    ) : <></>}
                </div>
            </div>
        )
    }

    const openPopupHandler = () => {
        setOpenPopup(true);
    }

    const closePopupHandler = () => {
        setOpenPopup(false);
    }

    const confirmPopup = () => {
        finishHandler();
    }

    return {
        getStep,
        closePopupHandler,
        openPopup,
        confirmPopup,
        element,
    }
}
