import {TypeErrorMessage, ErrorResponse} from "../../types/global";
import {ErrorMessage} from "../../views/common/enum/ErrorMessage";
import EventBusService from "./EventBusService";
import i18next from 'i18next';
import {GlobalMessage} from "../../views/common/enum/GlobalMessage";

/**
 * Error handling service
 */
export class ErrorService {
    private readonly t: (key: string) => string;

    constructor() {
        this.t = i18next.t;
    }

    public getErrorMessage(error: ErrorResponse): TypeErrorMessage {

        if (error.message === ErrorMessage.INVALID_INVITATION_CODE) {
            return this.t("incorrectCode");
        }

        if (error.message === ErrorMessage.INVALID_EMAIL) {
            return this.t("emailIsNotRegister");
        }

        if (error.message === ErrorMessage.COULD_NOT_CONVERT) {
            return this.t("unitConversionError");
        }

        if (error.error && !error.message) {
            return error.error ?? "Bad Request";
        }

        if (!error.message) {
            return this.t("unknownError");
        }

        return error.message;
    }

    /**
     * Handle service errors
     * @param err
     */
    public processServerError(err: ErrorResponse): void {
        if (err.message === ErrorMessage.NOT_AUTHORIZED) {
            ErrorService.showUnauthorizedError();
            return;
        }

        if (err.message === ErrorMessage.DEMO_USER) {
            this.showDescriptionGlobalNotification();
            return;
        }

        if (err.message !== ErrorMessage.INVALID_CREDENTIALS &&
            err.message !== ErrorMessage.DUPLICATE_EMAIL &&
            err.message !== ErrorMessage.FAILED_VALIDATION &&
            err.message !== ErrorMessage.NOT_FOUND &&
            err.message !== ErrorMessage.ALREADY_USED &&
            err.message !== ErrorMessage.ALREADY_EXIST &&
            err.message !== ErrorMessage.WRONG_PROMO_CODE &&
            err.message !== ErrorMessage.NO_ACTIVE_PROMO_CODE
        ) {
            ErrorService.showGlobalError(this.getErrorMessage(err));
        }
    }

    public processServerError500(): void {
        ErrorService.showGlobalError(this.t("somethingWentWrongTryAgain"));
    }

    private static showUnauthorizedError(): void {
        EventBusService.triggerEvent(
            ErrorMessage.NOT_AUTHORIZED
        );
    }

    public showDescriptionGlobalNotification(): void {
        EventBusService.triggerEvent(
            GlobalMessage.GLOBAL_DESCRIPTION_NOTIFICATION,
            this.t("demoUser")
        );
    }

    /**
     * @param err
     */
    private static showGlobalError(err: unknown): void {
        EventBusService.triggerEvent(
            ErrorMessage.GLOBAL_ERROR, err
        );
    }
}

const errorService = new ErrorService();

export default errorService;
