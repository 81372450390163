import React, {useEffect, useState} from "react";
import "../../assets/scss/cookie.scss";
import CookiesService from "../../js/services/CookiesService";
import { CookieName } from "../common/enum/CookieName";
import {ReactSVG} from "react-svg";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";

export default function Cookie() {
    const [hasCookie, setHasCookie] = useState(true);
    const { t } = useTranslation();
    const checkCookie = () => {
        return CookiesService.getCookie(CookieName.POPUP) === '1';
    }

    const setCookie = () => {
        CookiesService.setCookie(CookieName.POPUP, '1', 365);
        setHasCookie(true);
    }

    useEffect(() => {
        setHasCookie(checkCookie());
        // eslint-disable-next-line;
    }, []);

    const closeCookie = () => {
        setHasCookie(true);
    }

    return (
        hasCookie ? (
            <></>
        ) : (
            <div className={"cookie"}>
                <div className={"cookie__wrapper"}>
                    <div className={"cookie__content"}>
                        <div className={"cookie__image"}>
                            <ReactSVG src="/img/svg/ic_cookie.svg" className={"icon-svg"}/>
                        </div>
                        <p className={"cookie__title"}>
                            {t("cookieTitle")}
                        </p>
                        <p className={"cookie__text"} dangerouslySetInnerHTML={{ __html: t("cookieText")}}>
                        </p>
                        <div className={"cookie__buttons"}>
                            <Button
                                onClick={closeCookie}
                                className="btn btn--white"
                                type={"button"}>
                                {t("close")}
                            </Button>
                            <Button
                                onClick={setCookie}
                                className="btn"
                                type={"button"}>
                                {t("accept")}
                            </Button>
                        </div>
                    </div>
                    <Button
                        onClick={closeCookie}
                        type={"button"}
                        className={"cookie__close"}>
                        <ReactSVG src="/img/svg/ic_cancel.svg" className={"icon-svg"}/>
                    </Button>
                </div>
            </div>
        )
    )
}