export const enum ErrorMessage {
    DUPLICATE_EMAIL = "Duplicate email",
    INVALID_CREDENTIALS = "Invalid credentials",
    NOT_AUTHORIZED = "Not authorized",
    GLOBAL_ERROR = 'Global error',
    FAILED_VALIDATION = 'Request validation failed',
    INVALID_INVITATION_CODE = 'Invalid invitation code',
    INVALID_EMAIL = 'Invalid email',
    COULD_NOT_CONVERT = 'Could not convert',
    NOT_FOUND = 'Not found',
    ALREADY_EXIST = 'Already exists',
    ALREADY_USED = 'Already used',
    WRONG_PROMO_CODE = "Wrong promo",
    DEMO_USER = "Not available for the test client",
    CONFIRMED_EMAIL = "Confirmed",
    NOT_SENT = "Was not sent",
    INVALID_PASSWORD = "Invalid password",
    NO_ACTIVE_PROMO_CODE = "No active",
    SOMETHING_WAS_WRONG = "Something was wrong"
}