import ApiService from "../../../js/services/ApiService";
import {ClientsList} from "../../../types/clients-list";
import {Language} from "../../common/enum/Language";
import {Client} from "../../../types/client";

export default class ClientListApi {
    static getClients(): Promise<ClientsList.ListResponse> {
        return ApiService.getRequest('get', 'crm/nutritionist/clients' );
    }
    static getClient(
        id: number,
    ): Promise<ClientsList.Item> {
        return ApiService.getRequest('get', `crm/nutritionist/client?client_id=${id}` );
    }

    static clientInvite(
        params: ClientsList.InviteRequestParams
    ): Promise<void> {
        return ApiService.getRequest('post', 'crm/nutritionist/clients/invite' , {
            params
        });
    }

    static addClient(
        params: ClientsList.AddClientRequestParams
    ): Promise<void> {
        return ApiService.getRequest('post', 'crm/nutritionist/client' , {
            params
        });
    }

    static finishClient(
        client_id: number,
    ): Promise<void> {
        return ApiService.getRequest('post', `crm/nutritionist/client/${client_id}/status?status_action=FINISH`);
    }

    static restoreClient(
        client_id: number,
    ): Promise<void> {
        return ApiService.getRequest('post', `crm/nutritionist/client/${client_id}/status?status_action=RESUME`);
    }

    static getMeasure(
        client_id: number
    ): Promise<ClientsList.Measure> {
        return ApiService.getRequest('get', `crm/nutritionist/client/${client_id}/measure`);
    }

    static getAnswers(
        client_id: number,
        lang: Language
    ): Promise<ClientsList.AnswersResponse> {
        return ApiService.getRequest('get', `crm/nutritionist/client/${client_id}/answer?language=${lang}`);
    }

    static getSymptoms(
        client_id: number,
        lang: Language
    ): Promise<ClientsList.SymptomsResponse> {
        return ApiService.getRequest('get', `crm/nutritionist/client/${client_id}/symptom?language=${lang}`);
    }

    static downloadClientReport(
        client_id: number
    ): Promise<Blob> {
        return ApiService.getRequest('get', `/crm/nutritionist/client/${client_id}/report/download`, {
            showGlobalProcessError: false,
            responseType: 'blob'
        });
    }

    static inviteTg(
        client_id: number
    ): Promise<void> {
        return ApiService.getRequest('post', `crm/nutritionist/client/${client_id}/tg`, {
            showGlobalProcessError: false,
        });
    }

    static sendQuestionnaire(
        client_id: number,
        params: ClientsList.SendQuestionnaireRequest
    ): Promise<void> {
        return ApiService.getRequest('post', `crm/nutritionist/client/${client_id}/quest`, {
            params,
            showGlobalProcessError: false,
        });
    }

    static addEmail(
        client_id: number,
        email: string,
    ): Promise<void> {
        return ApiService.getRequest('post', `crm/nutritionist/client/${client_id}/email?email=${email}`);
    }

    static getCode(
        client_id: number,
    ): Promise<Client.Code> {
        return ApiService.getRequest('get', `crm/nutritionist/client/${client_id}/tg/code`);
    }
}
