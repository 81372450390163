import ApiService from "../../../js/services/ApiService";
import {Auth} from "../../../types/auth";

export default class UserApi {
    static getInfo(): Promise<Auth.User> {
        return ApiService.getRequest('get', 'user/info', {
            showGlobalProcessError: false,
        });
    }


    static updateOnboardingStep = (step: number) => {
        return ApiService.getRequest('post', `user/onboarding?step=${step}`, {
            showGlobalProcessError: false,
        });
    }

    static changePassword(
        params: Auth.ChangePasswordRequest
    ): Promise<void> {
        return ApiService.getRequest('post', 'user/password', {
            showGlobalProcessError: false,
            params
        });
    }
}
