import {UserActionTypes} from "../enum";
import {Auth} from "../../types/auth";

const initialState: {
    user: Auth.User | null,
    isLoginPage: boolean,
    demoUserId: number | null,
    isMobile: boolean
} = {
    user: null,
    isLoginPage: true,
    demoUserId: null,
    isMobile: false,
};

export const userReducer = (state = initialState, action: Auth.UserAction): {
    user: Auth.User,
    isLoginPage: boolean,
    demoUserId: number | null,
    isMobile: boolean
} => {
    switch(action.type) {
        case UserActionTypes.FETCH_USER:
            return {
                ...state,
                ...action.payload,
            }
        case UserActionTypes.CHANGE_LOGIN_PAGE:
            return {
                ...state,
                ...action.payload,
            }
        case UserActionTypes.SET_DEMO_USER_ID:
            return {
                ...state,
                ...action.payload,
            }
        case UserActionTypes.SET_IS_MOBILE_DEVICE:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}
