import axios, { AxiosRequestConfig } from 'axios';
import authService from "../js/services/AuthService";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    headers: {
        Accept: "application/json, text/plain, */*"
    },
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    const currentConfig: AxiosRequestConfig = config;

    currentConfig.params = config.params || {};
    const token = authService.getToken();
    if (token && currentConfig.headers) {
        currentConfig.headers.Authorization = `Bearer ${token}`;
    }
    return currentConfig;
});

export default axiosInstance;
