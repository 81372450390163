import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import "../../assets/scss/sidebar.scss";
import NavLinkMenu from "../components/NavLinkMenu";
import {useLeftSidebarMenu} from "./use/useLeftSidebarMenu";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {Link} from "react-router-dom";

function LeftSidebarMenu(): JSX.Element {
    const {
        navLinks,
        additionalNavLink,
        navLinksUserClient,
    } = useLeftSidebarMenu();
    const currentUserInfo = useTypedSelector((state) => state.user.user);
    const isMobile = useTypedSelector((state) => state.user.isMobile);

    const getNavLinksMenu = ():JSX.Element => {
        const navLinksResult = (currentUserInfo && currentUserInfo.role === 'Client')
            ? navLinksUserClient : navLinks;
        return (
            <>
                {navLinksResult.map((link) => (
                    <NavLinkMenu
                        key={link.path}
                        path={link.path}
                        title={isMobile ? "" : link.title}
                        icon={link.icon}
                        clickHandler={link.clickHandler}
                    />
                ))}
            </>
        )
    }

    const getNotificationContent = ():JSX.Element => {
        return (currentUserInfo && currentUserInfo.role === 'Client') ? (
            <div className={"sidebar__header-client-avatar"}>
                <Avatar
                    alt={currentUserInfo.name}
                    sx={{ width: 40, height: 40, borderRadius: "100%" }}
                />
            </div>
        ) : <></>
    }

    const getClassByRole = () => {
        if (currentUserInfo && currentUserInfo.role === 'Client') {
            return 'client'
        } else {
            return 'expert'
        }
    }

    return (
        <Box component="div" className={`sidebar ${getClassByRole()}`}>
            {isMobile ? (
                <div className={"sidebar__header"}>
                    <div className={"sidebar__header-top"}>
                        <Link to={'/'} className={"sidebar__header-title"}>
                            <img src={"/img/main/logo-mobile.svg"} alt={"nutripy"} loading={"lazy"}/>
                        </Link>
                    </div>
                </div>
            ) : (
                <div className={"sidebar__header"}>
                    <div className={"sidebar__header-top"}>
                        <Link to={'/'} className={"sidebar__header-title"}>
                            <img src={"/img/main/logo.svg"} alt={"nutripy"} loading={"lazy"}/>
                        </Link>
                        { getNotificationContent() }
                    </div>
                    <div className={"sidebar__header-bottom"}>
                        <p className={"sidebar__header-name"}>
                            {currentUserInfo?.name}
                        </p>
                    </div>
                </div>
            )}
            <Box component="div"
                 className="sidebar__wrapper">
                <div>
                    { getNavLinksMenu() }
                </div>
                <div>
                    {additionalNavLink.map((link) => (
                        <NavLinkMenu
                            key={link.path}
                            path={link.path}
                            title={isMobile ? "" : link.title}
                            icon={link.icon}
                            clickHandler={link.clickHandler}
                        />
                    ))}
                </div>
            </Box>
        </Box>
    );
}

export default LeftSidebarMenu;
