import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import React, {useEffect, useState} from "react";
import {OnboardingStep} from "../../common/enum/OnboardingStep";
import {Auth} from "../../../types/auth";
import UserApi from "../../user/api/UserApi";
import {useActions} from "../../../hooks/useAction";

export function useOnboardingMobile() {
    const { t, i18n } = useTranslation();
    const [openPopup, setOpenPopup] = useState(false);
    const currentUserInfo = useTypedSelector((state) => state.user.user);
    const SHOW_ONBOARDING = "onboarding-active";
    const {setStateUser} = useActions();
    const [step, setStep] = useState<number>(1);
    const SECONDS_TIMER = 10000;
    const ALL_COUNT_STEPS = 16;
    const [timerTouchId, setTimerTouchId] = useState<NodeJS.Timer | null>(null);
    const [timerId, setTimerId] = useState<NodeJS.Timer | null>(null);
    const [isPausedAnimation, setIsPausedAnimation] = useState(false);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [timeoutTimerId, setTimeoutTimerId] = useState<NodeJS.Timer | null>(null);

    const updateStepOnboarding = async (stepNumber: number) => {
        try {
            await UserApi.updateOnboardingStep(stepNumber);
        } catch (e: any){
            console.log(e);
        }
    }

    useEffect(() => {
        startOnboarding();

        return clearTimer;
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (step === ALL_COUNT_STEPS) {
            clearTimer();
            finishHandler();
        }
        // eslint-disable-next-line
    }, [step]);

    const startOnboarding = () => {
        clearTimer();

        const intervalId = setInterval(nextStep, SECONDS_TIMER);

        setTimerId(intervalId);
    }

    const clearTimer = () => {
        if (timerId) {
            clearInterval(timerId)
        }
    }

    const openPopupHandler = () => {
        setOpenPopup(true);
    }

    const closePopupHandler = () => {
        setOpenPopup(false);
    }

    const confirmPopup = () => {
        finishHandler();
    }

    const finishHandler = () => {
        updateStepOnboarding(OnboardingStep.MAX_STEP);
        document.body.classList.remove(SHOW_ONBOARDING);

        const newUserInfo = {
            ...currentUserInfo,
            onboardingStep: OnboardingStep.MAX_STEP
        } as  Auth.User;

        setStateUser(newUserInfo);
    }

    const getText = () => {
        switch (step) {
            case 2:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileClientsList')}}
                    ></p>
                )
            case 3:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileClient')}}
                    ></p>
                )
            case 4:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileClientMenu')}}
                    ></p>
                )
            case 5:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileRecommendationPage')}}
                    ></p>
                )
            case 6:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileAnalysis')}}
                    ></p>
                )
            case 7:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileAnalysisPopup')}}
                    ></p>
                )
            case 8:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileAnalysisTable')}}
                    ></p>
                )
            case 9:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileAnalysisTableOld')}}
                    ></p>
                )
            case 10:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileSupplement')}}
                    ></p>
                )
            case 11:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileSupplementPopup')}}
                    ></p>
                )
            case 12:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileDailyScheduleBtn')}}
                    ></p>
                )
            case 13:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileDailySchedule')}}
                    ></p>
                )
            case 14:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileDiet')}}
                    ></p>
                )
            case 15:
                return (
                    <p dangerouslySetInnerHTML={{ __html: t('onboardingMobileFinish')}}
                    ></p>
                )
            default:
                return <></>
        }
    }

    const getImage = () => {
        const image1Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-1.jpg`;
        const image1Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-1.webp`;
        const image2Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-2.jpg`;
        const image2Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-2.webp`;
        const image3Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-3.jpg`;
        const image3Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-3.webp`;
        const image4Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-4.jpg`;
        const image4Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-4.webp`;
        const image5Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-5.jpg`;
        const image5Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-5.webp`;
        const image6Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-6.jpg`;
        const image6Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-6.webp`;
        const image7Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-7.jpg`;
        const image7Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-7.webp`;
        const image8Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-8.jpg`;
        const image8Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-8.webp`;
        const image9Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-9.jpg`;
        const image9Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-9.webp`;
        const image10Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-10.jpg`;
        const image10Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-10.webp`;
        const image11Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-11.jpg`;
        const image11Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-11.webp`;
        const image12Jpg = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-12.jpg`;
        const image12Webp = `/img/mobile-onboarding/${i18n.language.toLowerCase()}/onboarding-mobile-12.webp`;

        switch (step) {
            case 2:
                return (
                    <picture>
                        <source srcSet={image1Webp} type="image/webp" />
                        <img src={image1Jpg} alt="" />
                    </picture>
                )
            case 3:
                return (
                    <picture>
                        <source srcSet={image2Webp} type="image/webp" />
                        <img src={image2Jpg} alt="" />
                    </picture>
                )
            case 4:
                return (
                    <picture>
                        <source srcSet={image3Webp} type="image/webp" />
                        <img src={image3Jpg} alt="" />
                    </picture>
                )
            case 5:
                return (
                    <picture>
                        <source srcSet={image4Webp} type="image/webp" />
                        <img src={image4Jpg} alt="" />
                    </picture>
                )
            case 6:
                return (
                    <picture>
                        <source srcSet={image5Webp} type="image/webp" />
                        <img src={image5Jpg} alt="" />
                    </picture>
                )
            case 7:
                return (
                    <picture>
                        <source srcSet={image6Webp} type="image/webp" />
                        <img src={image6Jpg} alt="" />
                    </picture>
                )
            case 8:
                return (
                    <picture>
                        <source srcSet={image5Webp} type="image/webp" />
                        <img src={image5Jpg} alt="" />
                    </picture>
                )
            case 9:
                return (
                    <picture>
                        <source srcSet={image7Webp} type="image/webp" />
                        <img src={image7Jpg} alt="" />
                    </picture>
                )
            case 10:
                return (
                    <picture>
                        <source srcSet={image8Webp} type="image/webp" />
                        <img src={image8Jpg} alt="" />
                    </picture>
                )
            case 11:
                return (
                    <picture>
                        <source srcSet={image9Webp} type="image/webp" />
                        <img src={image9Jpg} alt="" />
                    </picture>
                )
            case 12:
                return (
                    <picture>
                        <source srcSet={image8Webp} type="image/webp" />
                        <img src={image8Jpg} alt="" />
                    </picture>
                )
            case 13:
                return (
                    <picture>
                        <source srcSet={image10Webp} type="image/webp" />
                        <img src={image10Jpg} alt="" />
                    </picture>
                )
            case 14:
                return (
                    <picture>
                        <source srcSet={image11Webp} type="image/webp" />
                        <img src={image11Jpg} alt="" />
                    </picture>
                )
            case 15:
                return (
                    <picture>
                        <source srcSet={image12Webp} type="image/webp" />
                        <img src={image12Jpg} alt="" />
                    </picture>
                )
            default:
                return <></>
        }
    }

    const getContent = (): JSX.Element => {
        if (step === 1) {
            return (
                <div className={"onboarding-layout__main-content"}>
                    <p className={"onboarding-layout__main-content-title"}>
                        {t("welcome")},<br/>
                        {currentUserInfo?.name} {currentUserInfo?.surname}!<br/><br/>
                        {t("onboardingSuccessfulRegistration")} 🎉 <br/>
                        {t("onboardingShowAround")}
                    </p>
                </div>
            )
        }

        return (
            <div className={"onboarding-layout__step-content"}>
                <div className={"onboarding-layout__step-content-image"}>
                    {getImage()}
                </div>
                <div className={"onboarding-layout__step-content-text"}>
                    <div className={"onboarding-layout__step-content-text-wrapper"}>
                        {getText()}
                    </div>
                </div>
            </div>
        )
    }

    const skipHandler = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        openPopupHandler();
    }

    const stepsProgress = new Array(ALL_COUNT_STEPS - 1).fill(null);

    const nextStep = () => {
        if (step === ALL_COUNT_STEPS) {
            return;
        }

        setStartTime(new Date());
        setStep(prevStep => prevStep + 1);
    }

    const prevStep = () => {
        if (step === 1) {
            return;
        }

        setStep(step - 1);
    }

    const touchStart = () => {
        if (timerId) {
            clearInterval(timerId);
        }

        if (timeoutTimerId) {
            clearInterval(timeoutTimerId);
        }

        setEndTime(new Date());

        const touchTimer = setTimeout(() => {
            setIsPausedAnimation(true);
        }, 500);

        setTimerTouchId(touchTimer);
    }

    const touchEnd = (isNext: boolean) => {
        if (!isPausedAnimation) {
            if (!isNext) {
                prevStep();
            } else {
                nextStep();
            }
            startOnboarding();
        }

        if (isPausedAnimation) {
            let diff = 0;
            if (startTime && endTime) {
                // @ts-ignore
                diff = endTime - startTime;
            }

            const timeoutId = setTimeout(() => {
                nextStep();
                startOnboarding();
            }, SECONDS_TIMER - diff);

            setTimeoutTimerId(timeoutId);
        }

        if (timerTouchId) {
            clearTimeout(timerTouchId);
            setIsPausedAnimation(false);
        }
    }

    return {
        openPopup,
        confirmPopup,
        closePopupHandler,
        getContent,
        skipHandler,
        step,
        stepsProgress,
        touchStart,
        touchEnd,
        isPausedAnimation,
    }
}
