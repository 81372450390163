import React from "react";
import {Button, Dialog, DialogContent} from "@mui/material";
import {useTranslation} from "react-i18next";
import "../../assets/scss/popup.scss";

type OnboardingPopupProps = {
    openPopup: boolean,
    closeHandler: () => void,
    confirmPopup: () => void,
}
function OnboardingPopup(props: OnboardingPopupProps):JSX.Element  {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.openPopup}
            onClose={props.closeHandler}
            className={"popup onboarding-layout__popup"}
        >
            <DialogContent className={"popup__wrapper"}>
                <div className={"popup__content"}>
                    <div className={"onboarding-layout__popup-title"}>
                        {t("onboardingFinishMessage")}
                    </div>
                    <div className={"onboarding-layout__popup-buttons"}>
                        <Button
                            className={`btn btn--gray`}
                            onClick={props.closeHandler}
                        >
                            {t("no")}
                        </Button>
                        <Button
                            className={`btn btn--full`}
                            onClick={props.confirmPopup}
                        >
                            {t("yes")}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default OnboardingPopup;
