import React, {useEffect, useState, lazy, Suspense} from 'react';
import Box from "@mui/material/Box";
import {useUser} from "./views/user/use/useUser";
import Loading from "./views/components/Loading";
import SnackBar from "./views/components/SnackBar";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEventService} from "./views/common/use/useEventService";
import {useTranslation} from "react-i18next";
import {Role} from "./views/common/enum/Role";
import authService from "./js/services/AuthService";
import { PageUrl } from './views/common/enum/PageUrl';
import UserApi from "./views/user/api/UserApi";
import {Auth} from "./types/auth";
import {useActions} from "./hooks/useAction";
import {OnboardingStep} from "./views/common/enum/OnboardingStep";
import Onboarding from './views/onboarding/Onboarding';
import Default from "./views/layouts/Default";
import {useTypedSelector} from "./hooks/useTypedSelector";
import OnboardingMobile from "./views/onboarding/OnboardingMobile";
import Cookie from "./views/components/Cookie";

function App(): JSX.Element {
    const {
        currentUserInfo,
        isLoading,
        logout,
        demoUserId,
        fetchData,
    } = useUser();

    const {
        initEvents,
        destroyEvents,
        message,
        isOpenSnackBar,
        severity,
        setIsOpenSnackBar,
        setMessage,
        setSeverity,
    } = useEventService(logout);

    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const locationSearch = useLocation().search;
    const locationName = useLocation().pathname;
    const [isResetPasswordPage, setIsResetPasswordPage] = useState(false);
    const [isResetEmail, setIsResetEmail] = useState(false);
    const [isUploadMedtestsFilePage, setIsUploadMedtestsFilePage] = useState(false);
    const [isPaymentSuccessPage, setIsPaymentSuccessPage] = useState(false);
    const [isQuestionnairePage, setQuestionnairePage] = useState(false);
    const [isRestorePasswordPage, setIsRestorePasswordPage] = useState(false);
    const RESET_PASSWORD_URL = "reset-password";
    const RESET_EMAIL_URL = "change-email";
    const UPLOAD_MEDTESTS_FILE_URL = "upload-medtests-file";
    const RESTORE_PASSWORD = "restore-password";
    const QUESTIONNAIRE_TEMPLATE = "questionnaires";
    const isMobile = useTypedSelector((state) => state.user.isMobile);

    const navigate = useNavigate();
    const {setStateUser, setIsMobileDevice} = useActions();

    const changeLanguage = (lng: string) => {
        void i18n.changeLanguage(lng);
    };

    const getInfoUser = async () => {
        let userInfo: Auth.User = null;
        try {
            userInfo = await UserApi.getInfo();
        } catch (e: any){
            console.log(e);
        }

        return userInfo
    }

    useEffect(() => {
        if (currentUserInfo) {
            changeLanguage(currentUserInfo.language.toLowerCase());

            if (searchParams.get("success_add_card")) {
                setMessage(t("addCardSuccess"));
                setSeverity("success");
                setIsOpenSnackBar(true);
            }
        }
        // eslint-disable-next-line
    }, [currentUserInfo]);

    /**
     * First render
     */
    useEffect(() => {
        initEvents();
        fetchData();
        setHeight();
        checkMobileDevice();

        // returned function will be called on component unmount
        return () => {
            destroyEvents();
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsResetPasswordPage(locationName.includes(RESET_PASSWORD_URL));
        setIsResetEmail(locationName.includes(RESET_EMAIL_URL));
        setIsUploadMedtestsFilePage(locationName.includes(UPLOAD_MEDTESTS_FILE_URL));
        setQuestionnairePage(locationName.includes(QUESTIONNAIRE_TEMPLATE));
        setIsRestorePasswordPage(locationName.includes(RESTORE_PASSWORD));
    }, [locationName]);

    const checkPaymentStatus = () => {
        let intervalId = setInterval(async () => {
            const userInfo = await getInfoUser();

            if (userInfo && userInfo.hasAccess) {
                setStateUser(userInfo);
                clearInterval(intervalId);
                void refreshTokenHandlerAndRedirect();
            }
        }, 1000);
    }

    useEffect(() => {
        if (locationSearch.includes(PageUrl.PAYMENT_URL)) {
            setIsPaymentSuccessPage(true);
            checkPaymentStatus();
        }

        // eslint-disable-next-line
    }, [locationSearch]);

    const setHeight = () => {
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        document.documentElement.style.setProperty('--window-height', `${windowHeight}px`);
    }

    const checkMobileDevice = () => {
        const MAX_MOBILE_WIDTH = 768;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        setIsMobileDevice(windowWidth < MAX_MOBILE_WIDTH);
    }

    const refreshTokenHandlerAndRedirect = async () => {
        await authService.refreshToken();
        navigate('/');
        setIsPaymentSuccessPage(false);
    }

    if (isLoading) {
        return (
            <Box component="div"
                 sx={{ display: 'flex',
                     minHeight: 'calc(var(--window-height))',
                     flexDirection: 'column',
                     justifyContent: 'center'
                 }}>
                <Loading/>
            </Box>
        );
    }

    const RestorePasswordLoad =
        lazy(() => import('./views/recover-password/RestorePassword'));

    if (isRestorePasswordPage) {
        return (
            <>
                <Suspense>
                    <RestorePasswordLoad/>
                </Suspense>
                <SnackBar
                    isOpen={isOpenSnackBar}
                    handleClose={setIsOpenSnackBar}
                    message={message}
                    severity={severity}
                />
            </>
        );
    }

    const ResetPasswordLoad =
        lazy(() => import('./views/recover-password/ResetPassword'));

    if (isResetPasswordPage) {
        return (
            <>
                <Suspense>
                    <ResetPasswordLoad/>
                </Suspense>
                <SnackBar
                    isOpen={isOpenSnackBar}
                    handleClose={setIsOpenSnackBar}
                    message={message}
                    severity={severity}
                />
            </>
        );
    }

    const ChangeEmailLoad =
        lazy(() => import('./views/layouts/ChangeEmail'));
    if (isResetEmail) {
        return (
            <>
                <Suspense>
                    <ChangeEmailLoad/>
                </Suspense>
                <SnackBar
                    isOpen={isOpenSnackBar}
                    handleClose={setIsOpenSnackBar}
                    message={message}
                    severity={severity}
                />
            </>
        );
    }

    const BuySuccessPageLoad =
        lazy(() => import('./views/tariffs-page/BuySuccessPage'));

    if (isPaymentSuccessPage) {
        return (
            <Suspense>
                <BuySuccessPageLoad/>
            </Suspense>
        )
    }

    const EmailConfirmLoad =
        lazy(() => import('./views/components/email-confirm/EmailConfirm'));

    if (currentUserInfo
        && !currentUserInfo.emailVerified
    ) {
        return (
            <Suspense>
                <EmailConfirmLoad
                    setMessage={setMessage}
                    setIsOpenSnackBar={setIsOpenSnackBar}
                    setSeverity={setSeverity}
                />
            </Suspense>
        );
    }

    const BuyPageLoad =
        lazy(() => import('./views/tariffs-page/BuyPage'));

    if (currentUserInfo
        && !currentUserInfo.paid
        && currentUserInfo.role === Role.NUTRITIONIST) {
        return (
            <Suspense>
                <BuyPageLoad/>
            </Suspense>
        );
    }

    const UploadMedtestsFileLoad =
        lazy(() => import('./views/client/upload-medtests-file/UploadMedtestsFile'));

    if (isUploadMedtestsFilePage) {
        return (
            <Suspense>
                <UploadMedtestsFileLoad/>
            </Suspense>
        )
    }

    const ClientQuestionnaireLoad =
        lazy(() => import('./views/role-client/questionnaire/ClientQuestionnaire'));

    if (isQuestionnairePage) {
        return (
            <Suspense>
                <ClientQuestionnaireLoad/>
            </Suspense>
        )
    }

    const LoginLoad =
        lazy(() => import('./views/layouts/Login'));

    const isActiveOnboarding = currentUserInfo &&
        demoUserId &&
        currentUserInfo.onboardingStep < OnboardingStep.MAX_STEP;

    return (
        <>
            {isActiveOnboarding ? (
                isMobile ? <OnboardingMobile/> : <Onboarding/>
            ) : <></>}
            <Box component="div"
                 sx={{ display: 'flex',
                     minHeight: 'calc(var(--window-height))',
                     flexDirection: 'column',
                 }}>
                <div>
                    {currentUserInfo ?
                        (
                            <>
                                <Default/>
                                <Cookie/>
                            </>
                        ) : (
                            <Suspense>
                                <LoginLoad/>
                            </Suspense>
                        )
                    }
                </div>
                <SnackBar
                    isOpen={isOpenSnackBar}
                    handleClose={setIsOpenSnackBar}
                    message={message}
                    severity={severity}
                />
            </Box>
        </>
    );
}

export default App;
