import {ApiRequestOptions, ErrorResponse, FormDataItemObject} from "../../types/global";
import axiosInstance from "../../axios";
import {AxiosError, AxiosResponse} from "axios";
import errorService from "./ErrorService";

export default class ApiService {
    static getRequest = <T>(
        method: 'post' | 'get' | 'put' | 'delete',
        url: string,
        entryOptions: ApiRequestOptions = {}
    ): Promise<T> => {
        const requestParams: Record<string, unknown> = {
            method,
            url,
        };

        const options: ApiRequestOptions = {
            needTransformPostData: false,
            showGlobalProcessError: true,
            ...entryOptions,
        };

        if (options.params) {
            if (method === 'post' || method === 'put') {
                requestParams.data = options.needTransformPostData
                    ? ApiService.getFormDataByObject(options.params)
                    : options.params;
            } else {
                requestParams.params = options.params;
            }
        }

        if (options.responseType) {
            requestParams.responseType = options.responseType;
        }

        if (options.headers) {
            requestParams.header = options.headers;
        }

        return new Promise((resolve, reject) => {
            axiosInstance
                .request(requestParams)
                .then((response: AxiosResponse) => {
                    if (typeof response.data !== 'undefined' && response.data !== null) {
                        resolve(response.data as T);
                    } else {
                        resolve(response as T);
                    }
                })
                .catch((err: AxiosError) => {
                    if (!err.response?.data) {
                        errorService.processServerError500();
                    } else {
                        if (options.showGlobalProcessError) {
                            errorService.processServerError(err.response?.data as ErrorResponse);
                        }
                    }
                    reject(err.response?.data);
                });
        });
    };

    /**
     * Делает FormData из произвольного объекта
     *
     * @param { [key: string]: string | FileList | Object} paramsObject
     * @param {FormData} formData
     * @param {string} previousFormDataKey
     * @return FormData
     * @private
     */
    static getFormDataByObject(
        paramsObject: FormDataItemObject,
        formData: FormData = new FormData(),
        previousFormDataKey = ''
    ): FormData {
        Object.keys(paramsObject as FormDataItemObject).forEach((propName: string) => {
            let field = paramsObject[propName];
            let formDataKey = previousFormDataKey
                ? `${previousFormDataKey}[${propName}]`
                : propName;

            if (typeof field === 'boolean') {
                formData.append(formDataKey, field ? '1' : '0');
                return;
            }

            if (typeof field === 'number') {
                formData.append(formDataKey, `${field}`);
                return;
            }

            if (field) {
                if (field instanceof FileList) {
                    field = Array.from(field);
                }

                if (Array.isArray(field)) {
                    formDataKey = `${formDataKey}`;

                    field.forEach((arrayFieldElement, index) => {
                        if (
                            typeof arrayFieldElement === 'object' &&
                            !(arrayFieldElement instanceof File)
                        ) {
                            this.getFormDataByObject(
                                arrayFieldElement,
                                formData,
                                `${formDataKey}[${index}]`
                            );
                            return;
                        }
                        formData.append(
                            `${formDataKey}[]`,
                            arrayFieldElement?.toString()
                        );
                    });
                } else if (
                    field &&
                    typeof field === 'object' &&
                    !(field instanceof File)
                ) {
                    this.getFormDataByObject(field, formData, formDataKey);
                } else {
                    formData.append(formDataKey, field);
                }
            }
        });

        return formData;
    }
}
