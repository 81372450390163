import {useState} from "react";
import UserApi from "../api/UserApi";
import {useActions} from "../../../hooks/useAction";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import authService from "../../../js/services/AuthService";
import { useNavigate } from 'react-router-dom';
import ClientListApi from "../../clients-list/api/ClientListApi";

export function useUser() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {setStateUser, setDemoUserId} = useActions();
    const currentUserInfo = useTypedSelector((state) => state.user.user)
    const demoUserId = useTypedSelector((state) => state.user.demoUserId)
    const navigate = useNavigate();

    const getCurrentUser = async () => {
        let userInfo = null;
        try {
            userInfo = await UserApi.getInfo();
        } catch (e: any){
            console.log(e);
        }

        setStateUser(userInfo);
        return userInfo;
    }

    const getClients = async () => {
        let clientsResponse;
        try {
            clientsResponse = await ClientListApi.getClients();
        } catch (e: any){
            console.log(e);
        }
        return clientsResponse
    };

    const logout = () => {
        navigate('/');
        authService.logout();
        setStateUser(null);
    }

    const getDemoUserId = async () => {
        let id = null;

        const clientResponse = await getClients();

        if (clientResponse) {
            id = clientResponse.clients.find(client => client.isDemo)?.clientId ?? null;
        }

        setDemoUserId(id);
    }

    const fetchData = async () => {
        if  (authService.getToken()) {
           await getCurrentUser();
        }

        setIsLoading(false);
    }

    return {
        isLoading,
        currentUserInfo,
        logout,
        fetchData,
        demoUserId,
        getDemoUserId,
    }
}
