import React from "react";
import "../../assets/scss/onboarding-layout.scss";
import OnboardingPopup from "./OnboardingPopup";
import {useOnboarding} from "./use/useOnboarding";

function Onboarding() {
    const {
        getStep,
        closePopupHandler,
        openPopup,
        confirmPopup,
        element
    } = useOnboarding();

    return (
        <div className={`onboarding-layout ${openPopup ? "show-popup" : ""}`} id={"onboarding"}>
            <div className={"onboarding-layout__wrapper"}></div>
            {getStep(element)}
            <OnboardingPopup
                openPopup={openPopup}
                closeHandler={closePopupHandler}
                confirmPopup={confirmPopup}
            />
        </div>
    );
}

export default Onboarding;
