import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import nsEn from './en/ns.json';
import nsRu from './ru/ns.json';
import {Language} from "../views/common/enum/Language";

const userLanguage = navigator.language;

i18next.use(initReactI18next).init({
    lng: userLanguage.toUpperCase().includes(Language.EN) ? Language.EN.toLowerCase() : Language.RU.toLowerCase(),
    resources: {
        en: {
            translations: nsEn
        },
        ru: {
            translations: nsRu
        }
    },
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: true,
    interpolation: {
        escapeValue: false
    },
});