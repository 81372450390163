import React from "react";
import "../../assets/scss/onboarding-layout.scss";
import OnboardingPopup from "./OnboardingPopup";
import {useOnboardingMobile} from "./use/useOnboardingMobile";
import {Button} from "@mui/material";

function OnboardingMobile() {
    const {
        openPopup,
        confirmPopup,
        closePopupHandler,
        getContent,
        skipHandler,
        step,
        stepsProgress,
        touchStart,
        touchEnd,
        isPausedAnimation,
    } = useOnboardingMobile();

    return (
        <div className={`onboarding-layout onboarding-layout--mobile ${openPopup ? "show-popup" : ""}`} id={"onboarding"}>
            <div className={`onboarding-layout__background ${ step!== 1 ? "blackout" : ""}`}>
                <picture>
                    <source srcSet="/img/main/onboarding-background.webp" type="image/webp" />
                    <img src="/img/main/onboarding-background.jpg" alt="" />
                </picture>
            </div>
            <div className={"onboarding-layout__pagination"}>
                <div className={"onboarding-layout__pagination-wrapper"}>
                    <ul className={`onboarding-layout__step-progress ${isPausedAnimation ? "paused" : ""}`}>
                        {stepsProgress.map((item, index) => (
                            <li key={index} className={(index + 1) === step ? "active" : ""} ></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={"onboarding-layout__close"}>
                <Button
                    type={"button"}
                    onClick={skipHandler}
                >X</Button>
            </div>
            <div className={"onboarding-layout__content"}>
                <div className={"onboarding-layout__content-click"}>
                    <button
                        type={"button"}
                        className={"onboarding-layout__content-click-left"}
                        onTouchStart={touchStart}
                        onTouchEnd={() => touchEnd(false)}>
                    </button>
                    <button
                        type={"button"}
                        className={"onboarding-layout__content-click-right"}
                        onTouchStart={touchStart}
                        onTouchEnd={() => touchEnd(true)}>
                    </button>
                </div>
                {getContent()}
            </div>
            <OnboardingPopup
                openPopup={openPopup}
                closeHandler={closePopupHandler}
                confirmPopup={confirmPopup}
            />
        </div>
    );
}

export default OnboardingMobile;
