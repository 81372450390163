export default class EventBusService {
    /**
     * @param {string} eventName
     * @param data
     */
    public static triggerEvent(eventName: string, data?: unknown): void {
        if (typeof CustomEvent === 'function') {
            const event = new CustomEvent(eventName, {
                detail: data,
            });
            document.dispatchEvent(event);
        } else {
            const event = document.createEvent('CustomEvent');
            event.initCustomEvent(eventName, false, false, data);
            document.dispatchEvent(event);
        }
    }

    /**
     * @param eventName
     * @param handler
     */
    public static addEventListener(
        eventName: string,
        handler: EventListenerOrEventListenerObject
    ): void {
        document.addEventListener(eventName, handler, false);
    }

    /**
     * @param eventName
     * @param handler
     */
    public static removeEventListener(
        eventName: string,
        handler: EventListenerOrEventListenerObject
    ): void {
        document.removeEventListener(eventName, handler, false);
    }
}
