import {RouteObject} from "react-router-dom";
import React, { lazy, Suspense } from 'react';

const ClientPageLoad = lazy(() => import('../views/client/ClientPage'));
const HomeLoad = lazy(() => import('../views/Home'));
const ResetPasswordLoad = lazy(() => import('../views/recover-password/ResetPassword'));
const SettingsLoad = lazy(() => import('../views/settings/Settings'));
const ChangeEmailLoad = lazy(() => import('../views/layouts/ChangeEmail'));
const QuestionnaireLoad = lazy(() => import('../views/questionnaire/Questionnaire'));
const RestorePasswordLoad = lazy(() => import('../views/recover-password/RestorePassword'));

const routesConfig: RouteObject[] = [
    {
        children: [
            {
                path: "/",
                element: <Suspense><HomeLoad /></Suspense>,
            },
            {
                path: "/client/:id/*",
                element: <Suspense><ClientPageLoad /></Suspense>,
            },
            {
                path: "/reset-password/",
                element: <Suspense><ResetPasswordLoad /></Suspense>,
            },
            {
                path: "/settings/",
                element: <Suspense><SettingsLoad /></Suspense>,
            },
            {
                path: "/change-email/",
                element: <Suspense><ChangeEmailLoad /></Suspense>,
            },
            {
                path: "/questionnaire-template/",
                element: <Suspense><QuestionnaireLoad /></Suspense>,
            },
            {
                path: "/restore-password/",
                element: <Suspense><RestorePasswordLoad /></Suspense>,
            },
        ]
    },
];

export default routesConfig;
