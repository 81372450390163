import React, {useState} from "react";
import {SidebarMenu} from "../../../types/sidebar-menu";
import authService from "../../../js/services/AuthService";
import {useActions} from "../../../hooks/useAction";
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { ReactSVG } from 'react-svg';
import {PageUrl} from "../../common/enum/PageUrl";

export function useLeftSidebarMenu() {
    const [invisible] = useState(false);
    const {setStateUser} = useActions();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const logoutHandler = () => {
        navigate('/');
        authService.logout();
        setStateUser(null);
    }


    const navLinks: SidebarMenu.Item[] = [
        {
            path: "/",
            title: t("clients"),
            icon: <ReactSVG src="/img/svg/ic_clients.svg" className={"icon-svg"}/>
        },
        {
            path: "/questionnaire-template",
            title: t("questionnaire"),
            icon: <ReactSVG src="/img/svg/ic_questions.svg" className={"icon-svg"}/>,
        },
        {
            path: "/settings/",
            title: t("settings"),
            icon: <ReactSVG src="/img/svg/ic_settings.svg" className={"icon-svg"}/>,
        },
    ];

    const navLinksUserClient: SidebarMenu.Item[] = [
        {
            path: "/",
            title: t("main"),
            icon: <ReactSVG src="/img/svg/ic_main.svg" className={"icon-svg"}/>
        },
        {
            path: "/client/diets",
            title: t("diet"),
            icon: <ReactSVG src="/img/svg/ic_diet.svg" className={"icon-svg"}/>
        },
        {
            path: "/client/recommendations",
            title: t("recommendations"),
            icon: <ReactSVG src="/img/svg/ic_recommendations.svg" className={"icon-svg"}/>,
        },
        {
            path: "/client/analysis",
            title: t("analysis"),
            icon: <ReactSVG src="/img/svg/ic_medtests.svg" className={"icon-svg"}/>,
        },
        {
            path: "/client/questionnaires",
            title: t("questionnaires"),
            icon: <ReactSVG src="/img/svg/ic_questions.svg" className={"icon-svg"}/>,
        },
        {
            path: "/client/supplement",
            title: t("supplements"),
            icon: <ReactSVG src="/img/svg/ic_supplements.svg" className={"icon-svg"}/>,
        },
        {
            path: "/7",
            title: t("measurements"),
            icon: <ReactSVG src="/img/svg/ic_diet.svg" className={"icon-svg"}/>,
        },
    ];

    const additionalNavLink: SidebarMenu.Item[] = [
        {
            path: `${PageUrl.LANDING_URL}${i18n.language.toLowerCase()}/contact-us`,
            title: t("support"),
            icon: <ReactSVG src="/img/svg/ic_support.svg" className={"icon-svg"}/>,
        },
        {
            path: null,
            title: t("exit"),
            icon: <ReactSVG src="/img/svg/ic_exit.svg" className={"icon-svg"}/>,
            clickHandler: logoutHandler,
        },
    ];

    return {
        invisible,
        navLinks,
        additionalNavLink,
        navLinksUserClient,
    }
}
